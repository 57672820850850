html,
body {
  color: var(--bs-light);
  box-sizing: border-box;
  background-repeat: inherit;
  background-image: url("./1.jpg") !important;
}

.mt4 {
  margin-top: 4rem;
}

.carousel-indicator-item{
  text-indent: 0 !important;
}

.accordion-button:not(.options)::after{
  content: none !important;
}

.cursor-pointer{
  cursor: pointer;
}

.card a{
  text-decoration: none;
  font-weight: 600;
  color: var(--bs-dark);
}
.card a:hover{
  color: var(--bs-primary);
}

.breadcrumb-item a{
  text-decoration: none;
  color: #343a40;
}